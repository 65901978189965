
import universal, { setHasBabelPlugin } from '/var/task/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../../var/task/src/components/ErrorPage'/* webpackChunkName: "..-..-var-task-src-components-ErrorPage" */), universalOptions)
const t_1 = universal(import('../../var/task/src/components/Home'/* webpackChunkName: "..-..-var-task-src-components-Home" */), universalOptions)
const t_2 = universal(import('../../var/task/src/components/About'/* webpackChunkName: "..-..-var-task-src-components-About" */), universalOptions)
const t_3 = universal(import('../../var/task/src/components/Aquas'/* webpackChunkName: "..-..-var-task-src-components-Aquas" */), universalOptions)
const t_4 = universal(import('../../var/task/src/components/Careers'/* webpackChunkName: "..-..-var-task-src-components-Careers" */), universalOptions)
const t_5 = universal(import('../../var/task/src/components/Contact'/* webpackChunkName: "..-..-var-task-src-components-Contact" */), universalOptions)
const t_6 = universal(import('../../var/task/src/components/Crest'/* webpackChunkName: "..-..-var-task-src-components-Crest" */), universalOptions)
const t_7 = universal(import('../../var/task/src/components/CustomTank'/* webpackChunkName: "..-..-var-task-src-components-CustomTank" */), universalOptions)
const t_8 = universal(import('../../var/task/src/components/Knight'/* webpackChunkName: "..-..-var-task-src-components-Knight" */), universalOptions)
const t_9 = universal(import('../../var/task/src/components/KnightXl'/* webpackChunkName: "..-..-var-task-src-components-KnightXl" */), universalOptions)
const t_10 = universal(import('../../var/task/src/components/3dModel'/* webpackChunkName: "..-..-var-task-src-components-3dModel" */), universalOptions)
const t_11 = universal(import('../../var/task/src/components/News'/* webpackChunkName: "..-..-var-task-src-components-News" */), universalOptions)
const t_12 = universal(import('../../var/task/src/components/NewsArticle'/* webpackChunkName: "..-..-var-task-src-components-NewsArticle" */), universalOptions)
const t_13 = universal(import('../../var/task/src/components/SearchResults'/* webpackChunkName: "..-..-var-task-src-components-SearchResults" */), universalOptions)
const t_14 = universal(import('../../var/task/src/components/Product'/* webpackChunkName: "..-..-var-task-src-components-Product" */), universalOptions)
const t_15 = universal(import('../../var/task/src/components/Product/Custom/Veritus'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-Veritus" */), universalOptions)
const t_16 = universal(import('../../var/task/src/components/Product/Custom/Armor'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-Armor" */), universalOptions)
const t_17 = universal(import('../../var/task/src/components/Product/Custom/Shield'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-Shield" */), universalOptions)
const t_18 = universal(import('../../var/task/src/components/Product/Custom/Hellcat'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-Hellcat" */), universalOptions)
const t_19 = universal(import('../../var/task/src/components/Product/Custom/KnightXL'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-KnightXL" */), universalOptions)
const t_20 = universal(import('../../var/task/src/components/Product/Custom/Crest'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-Crest" */), universalOptions)
const t_21 = universal(import('../../var/task/src/components/Product/Custom/NewKnightXL'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-NewKnightXL" */), universalOptions)
const t_22 = universal(import('../../var/task/src/components/Product/Custom/Knight'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-Knight" */), universalOptions)
const t_23 = universal(import('../../var/task/src/components/Product/Custom/Noble'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-Noble" */), universalOptions)
const t_24 = universal(import('../../var/task/src/components/Product/Custom/Xcalibur'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-Xcalibur" */), universalOptions)
const t_25 = universal(import('../../var/task/src/components/Product/Custom/Xrgi'/* webpackChunkName: "..-..-var-task-src-components-Product-Custom-Xrgi" */), universalOptions)
const t_26 = universal(import('../../var/task/src/components/PackageSolutions'/* webpackChunkName: "..-..-var-task-src-components-PackageSolutions" */), universalOptions)
const t_27 = universal(import('../../var/task/src/components/RebateCenter'/* webpackChunkName: "..-..-var-task-src-components-RebateCenter" */), universalOptions)
const t_28 = universal(import('../../var/task/src/components/Lectrus'/* webpackChunkName: "..-..-var-task-src-components-Lectrus" */), universalOptions)
const t_29 = universal(import('../../var/task/src/components/POV'/* webpackChunkName: "..-..-var-task-src-components-POV" */), universalOptions)
const t_30 = universal(import('../../var/task/src/components/Shield'/* webpackChunkName: "..-..-var-task-src-components-Shield" */), universalOptions)
const t_31 = universal(import('../../var/task/src/components/Training'/* webpackChunkName: "..-..-var-task-src-components-Training" */), universalOptions)
const t_32 = universal(import('../../var/task/src/components/Veritus'/* webpackChunkName: "..-..-var-task-src-components-Veritus" */), universalOptions)
const t_33 = universal(import('../../var/task/src/components/Course'/* webpackChunkName: "..-..-var-task-src-components-Course" */), universalOptions)
const t_34 = universal(import('../../var/task/src/components/Register'/* webpackChunkName: "..-..-var-task-src-components-Register" */), universalOptions)
const t_35 = universal(import('../../var/task/src/components/WhereToBuy'/* webpackChunkName: "..-..-var-task-src-components-WhereToBuy" */), universalOptions)


// Template Map
export default {
  '../../var/task/src/components/ErrorPage': t_0,
'../../var/task/src/components/Home': t_1,
'../../var/task/src/components/About': t_2,
'../../var/task/src/components/Aquas': t_3,
'../../var/task/src/components/Careers': t_4,
'../../var/task/src/components/Contact': t_5,
'../../var/task/src/components/Crest': t_6,
'../../var/task/src/components/CustomTank': t_7,
'../../var/task/src/components/Knight': t_8,
'../../var/task/src/components/KnightXl': t_9,
'../../var/task/src/components/3dModel': t_10,
'../../var/task/src/components/News': t_11,
'../../var/task/src/components/NewsArticle': t_12,
'../../var/task/src/components/SearchResults': t_13,
'../../var/task/src/components/Product': t_14,
'../../var/task/src/components/Product/Custom/Veritus': t_15,
'../../var/task/src/components/Product/Custom/Armor': t_16,
'../../var/task/src/components/Product/Custom/Shield': t_17,
'../../var/task/src/components/Product/Custom/Hellcat': t_18,
'../../var/task/src/components/Product/Custom/KnightXL': t_19,
'../../var/task/src/components/Product/Custom/Crest': t_20,
'../../var/task/src/components/Product/Custom/NewKnightXL': t_21,
'../../var/task/src/components/Product/Custom/Knight': t_22,
'../../var/task/src/components/Product/Custom/Noble': t_23,
'../../var/task/src/components/Product/Custom/Xcalibur': t_24,
'../../var/task/src/components/Product/Custom/Xrgi': t_25,
'../../var/task/src/components/PackageSolutions': t_26,
'../../var/task/src/components/RebateCenter': t_27,
'../../var/task/src/components/Lectrus': t_28,
'../../var/task/src/components/POV': t_29,
'../../var/task/src/components/Shield': t_30,
'../../var/task/src/components/Training': t_31,
'../../var/task/src/components/Veritus': t_32,
'../../var/task/src/components/Course': t_33,
'../../var/task/src/components/Register': t_34,
'../../var/task/src/components/WhereToBuy': t_35
}

export const notFoundTemplate = "../../var/task/src/components/ErrorPage"
